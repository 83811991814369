export default {
  namespaced: true,
  state: {
    generalInfo: {
        steamid: null,
        token: null,
    }
  },
  mutations: {
    UPDATE_GENERAL_INFO(state, data) {
      Object.assign(state.generalInfo, data)
    },
  },
  actions: {
    UpdateGeneralInfo({commit}, data) {
      commit('UPDATE_GENERAL_INFO', data)
    },
  },
}