<template>
  <div id="app">
    <div class="page">
      <!--<sidebar-component v-if="this.$route.name != 'Login'"/>-->
      <!--<header-component v-if="this.$route.name != 'Login'"/>-->
      <div class="page-wrapper">
        <!--<wrapper-component v-if="this.$route.name != 'Login'"/>-->
        <div class="page-body">
          <router-view/>
        </div>
        <footer-component/>
      </div>
    </div>
  </div>
</template>

<script>
  //import SidebarComponent from '@/components/SidebarComponent.vue';
  //import HeaderComponent from '@/components/HeaderComponent.vue';
  import FooterComponent from '@/components/FooterComponent.vue';
  //import WrapperComponent from '@/components/WrapperComponent.vue';

  export default {
    name: 'AppView',
    components: {
      //SidebarComponent,
      //HeaderComponent,
      FooterComponent,
      //WrapperComponent
    },
    created() {
      /*let ckeditor = document.createElement('script');
      ckeditor.setAttribute('src',"dist/libs/apexcharts/dist/apexcharts.min.js");
      document.head.appendChild(ckeditor);*/
  
      /*let ckeditor1 = document.createElement('script');
      ckeditor1.setAttribute('src',"dist/libs/jsvectormap/dist/js/jsvectormap.min.js");
      document.head.appendChild(ckeditor1);*/
  
      /*let ckeditor2 = document.createElement('script');
      ckeditor2.setAttribute('src',"dist/libs/jsvectormap/dist/maps/world.js");
      document.head.appendChild(ckeditor2);*/
  
      /*let ckeditor3 = document.createElement('script');
      ckeditor3.setAttribute('src',"dist/libs/jsvectormap/dist/maps/world-merc.js");
      document.head.appendChild(ckeditor3);*/
  
      let ckeditor4 = document.createElement('script');
      ckeditor4.setAttribute('src',"dist/js/tabler.min.js");
      document.head.appendChild(ckeditor4);
  
      let ckeditor5 = document.createElement('script');
      ckeditor5.setAttribute('src',"dist/js/demo.min.js");
      document.head.appendChild(ckeditor5);
    }
  }
</script>
